import React , {useState} from 'react'
import "./Section1.css"
import switchImg from "./switch.png"
import mobile from "./mobile_cockpit.png"
import cockpit2 from "./cockpit2.png"
import Space from "./Space-bg 1.png"
import { NavLink } from 'react-router-dom'
import MediaQuery from 'react-responsive'

function Section1() {
    const [btn,setBtn]=useState(0)
    return (<>
    <img src={Space} id="bg" alt=""/>


    <img src={cockpit2} alt="" id="bg2" />


    <MediaQuery maxWidth={800}>
    <img src={mobile} alt="" id="bg2" />
    </MediaQuery>


    <div className="helbHF">
        <div className="launch" >LAUNCH</div>
        <div>p</div>
        <div>
        <NavLink exact to="/explore">   
        <button type="button" className="launch-button">
       <img className="switchImg"  src={switchImg} alt="" />
        </button>
        </NavLink>
        </div>

    <div className="evAkJH">

    
    <div style={{textAlign:'center'}}>
        <div  className="section1">
            <div className="sc-kstqJO ndcWI cont">
                <article className="article">
                <h1 className="title" style={{marginBottom:' 3rem'}}>Welcome to <br></br>
                <b style={{color:'#FE7A2B'}}>acknoledger!</b></h1>
                <p className="tilted__text">There was a time where metaverses were far away,<br></br>and a user could not jump from one to another. <br></br>Then came the groundbreaking acknoledger gateway <br></br>which promised to take every user to whichever metaverse they wanted, <br></br>whenever they wanted.<br></br>Acknowledger welcomes you on this ride to the stars.</p>
                </article>

            </div>
          
        </div>
        </div>
        
        </div>
        </div>
    </>)
}

export default Section1
