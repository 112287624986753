import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import {v4 as uuidv4} from "uuid";
import { config } from "react-spring";
import btn from "../Subscribe/Rectangle 414.png"
import "./DCarousel.css"
import title from "./Group 24.png"
import MediaQuery from 'react-responsive'
  import {Box,Paper,Grid,Button} from '@mui/material';
import { NavLink } from "react-router-dom";


  
export default class DCarousel extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.molasses,
    xDown:null,
    yDown:null,
    card:0,
    card2:0,
    card3:0,
    card4:0,
    card5:0,
  };
                    
  slides = [
     {
      key: uuidv4(),
       content: <img className="flip-card" 
       onClick={()=>{
       this.setState({card:!this.state.card,card2:false,card3:false,card4:false,card5:false })
       }} 
        src="https://demo.acknoledger.com/storage/axie-infinity-6.gif" alt="2" />
    },
    {
      key: uuidv4(),
      content: <img className="flip-card"  onClick={()=>{
        this.setState({card2:!this.state.card2,card:false,card3:false,card4:false,card5:false})
        }}  src="https://demo.acknoledger.com/storage/axie-infinity-7.gif" alt="3" />
    },
 
    {
      key: uuidv4(),
      content: <img className="flip-card"  onClick={()=>{
        this.setState({card3:!this.state.card3,card2:false,card:false,card4:false,card5:false})
        }}  src="https://demo.acknoledger.com/storage/axie-infinity-9.gif" alt="5" />
    },
    {
      key: uuidv4(),
      content: <img className="flip-card"  onClick={()=>{
        this.setState({card4:!this.state.card4,card2:false,card3:false,card:false,card5:false})
        }}  src="https://demo.acknoledger.com/storage/axie-infinity.gif" alt="6" />
    },
    {
      key: uuidv4(),
      content: <img className="flip-card"  onClick={()=>{
        this.setState({card5:!this.state.card5,card2:false,card3:false,card4:false,card:false})
        }}  src="https://demo.acknoledger.com/storage/axie-infinity-5.gif" alt="7" />
    }
   
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  onChangeInput = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10) || 0
    });
  };
  // let xDown = null;
  // let yDown = null;

getTouches = (evt) => {
    return (
      evt.touches || evt.originalEvent.touches // browser API
    ); // jQuery
  };

handleTouchStart = (evt) => {
    const firstTouch = this.getTouches(evt)[0];
 
    this.setState({ xDown: firstTouch.clientX })
    this.setState({ yDown: firstTouch.clientY })
  };

handleTouchMove = (evt) => {
    if (!this.xDown || !this.yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = this.xDown - xUp;
    let yDiff = this.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        this.setState({ goToSlide: this.state.goToSlide + 1 });
      } else {
        /* right swipe */
        this.setState({ goToSlide: this.state.goToSlide - 1 });
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    this.setState({ xDown: null })
    this.setState({ yDown: null })
   
  };
  render() {

     return (<>
    <div  id="gallery" className="part3__bg">

    <div id='stars'></div>
<div id='stars2'></div>
<div id='stars3'></div>

      <div className="select" style={{textAlign:'center'}}>
      <h1 className="artGallery">Select your metaverse</h1>
      <p className="metaverse__text">and know everythign there is to know.<br></br>
Nothing to hide, nothign to lose and one place to rule them all!</p>
                      
  </div>
  <Box id="select-your-metaverse" sx={{ flexGrow: 1,marginTop:'3rem' }}>
      <Grid container   alignItems="center" spacing={2}>
      
        <Grid style={{textAlign:'center'}} item xs={12} md={12}>
     <div className="upper">
          <div className="carousel__body"
           onTouchStart={this.handleTouchStart}
           onTouchMove={this.handleTouchMove}>
              <Carousel
            
                slides={this.slides}
                goToSlide={this.state.goToSlide}
                offsetRadius={this.state.offsetRadius}
                showNavigation={false}
                goToSlideDelay={200}
                animationConfig={this.state.config}
                
                  
              />
                   </div> 
              <div className="lower__part">
              {
  this.state.card ? <>
  {/* <h1 style={{color:'white'}}>jejej</h1> */}
  <div className="">
  <div className="info__card__title">
    <img src={title} alt=""/>
  </div>
  <div className="info__card__title__text">
    <h2>The Sandbox</h2>
  </div>
  </div>


  <div className="bkg__info__card" >
<svg className="bkg__img" viewBox="0 0 423 314" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_424:583)"><path d="M24.7578 20.7648V22.7457H421.022V257.17H379.418L366.391 270.831H64.0432V288.044H45.0144V274.383H24.0758V77.7316L14.3227 70.218V83.196L7.50228 76.3654V37.6363H24.485V12.8414H7.50228V0L-0.272949 9.76768V109.972L2.65982 113.25V163.045L8.72995 168.578V304.027L19.7108 314L21.0748 312.771L10.6396 303.276V167.826L4.56951 162.294V115.436L7.50228 118.783V91.8708L14.3227 98.7013V298.153L24.0758 307.511V299.109H45.0144V289.683H66.0212V272.402H366.936L379.963 259.356H422.659V20.7648H24.7578ZM20.8702 16.7348V33.6746H7.7751V16.7348H20.8702ZM7.7751 86.4063V81.9665L14.5955 88.797V93.2369L7.7751 86.4063Z" fill="#FE7A2B"></path></g><defs><filter id="filter0_d_424:583" x="-4.27295" y="0" width="430.932" height="322" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset dy="4"></feOffset><feGaussianBlur stdDeviation="2"></feGaussianBlur><feComposite in2="hardAlpha" operator="out"></feComposite><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_424:583"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_424:583" result="shape"></feBlend></filter></defs></svg>
</div>
<div >
          <div className="info-content">
            <div className="text-white">
            <p><b>Total Sales: </b> 29.7K ETH</p><p><b>Total Volume: </b>
             101.5K</p><p>Most competitive gaming metaverse where the battles are fierce.</p></div>
  
          </div>

        </div>
      
      

          
  </>: null
}
</div>
<div>
{
         this.state.card ?
         <>
                   <NavLink 
      
      exact to={{
        pathname:"/metaverse/:planet",
        state:{
          name:'The Sandbox',
          sales:'29.7K ETH',
          volume:'101.5K',
          image:'https://demo.acknoledger.com/storage/axie-infinity-6.gif'
        }
      }}
    
      > 
          <div>
              <div className="metaverse__btn__img">
                    <img src={btn} alt=""/>
              </div>
            
        
              <div className="metaverse__btn__img__text">
                KNOW MORE
              </div>
     
       
          </div> 
          </NavLink>
         </>
         :
         this.state.card2 ?
         
         <>
                   <NavLink 
      
      exact to={{
        pathname:"/metaverse/:planet",
        state:{
          name:'Gods Unchained',
          sales:'19.5K ETH',
          volume:'7.0M',
          image:'https://demo.acknoledger.com/storage/axie-infinity-7.gif'
        }
      }}
    
      > 
          <div>
              <div className="metaverse__btn__img">
                    <img src={btn} alt=""/>
              </div>
            
        
              <div className="metaverse__btn__img__text">
                KNOW MORE
              </div>
     
       
          </div> 
          </NavLink>
         </>
         :
         this.state.card3 ?
       
         <>
                   <NavLink 
      
      exact to={{
        pathname:"/metaverse/:planet",
        state:{
          name:'Splinterlands',
          sales:'15.8K ETH',
          volume:'2.3K',
          image:'https://demo.acknoledger.com/storage/axie-infinity-9.gif'
        }
      }}
    
      > 
          <div>
              <div className="metaverse__btn__img">
                    <img src={btn} alt=""/>
              </div>
            
        
              <div className="metaverse__btn__img__text">
                KNOW MORE
              </div>
     
       
          </div> 
          </NavLink>
         </>
         :
         this.state.card4 ?
         
         <>
                   <NavLink 
      
      exact to={{
        pathname:"/metaverse/:planet",
        state:{
          name:'Axie Infinity',
          sales:'27.5K ETH',
          volume:'282.8K',
          image:'https://demo.acknoledger.com/storage/axie-infinity.gif'
        }
      }}
    
      > 
          <div>
              <div className="metaverse__btn__img">
                    <img src={btn} alt=""/>
              </div>
            
        
              <div className="metaverse__btn__img__text">
                KNOW MORE
              </div>
     
       
          </div> 
          </NavLink>
         </>
         :
         this.state.card5 ?
         <>
                   <NavLink 
      
      exact to={{
        pathname:"/metaverse/:planet",
        state:{
          name:'Decentraland',
          sales:'52.8K ETH',
          volume:'97.1K',
          image:'https://demo.acknoledger.com/storage/axie-infinity-5.gif'
        }
      }}
    
      > 
          <div>
              <div className="metaverse__btn__img">
                    <img src={btn} alt=""/>
              </div>
            
        
              <div className="metaverse__btn__img__text">
                KNOW MORE
              </div>
     
       
          </div> 
          </NavLink>
         </>:null
 }

            
            </div>
<div className="lower__part">
{
  this.state.card2 ? <>
    <div className="">
      <div className="info__card__title">
    <img src={title} alt=""/>
  </div>
  <div className="info__card__title__text">
    <h2>Gods Unchained</h2>
  </div>
  </div>
  <div className="bkg__info__card" >
<svg className="bkg__img" viewBox="0 0 423 314" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_424:583)"><path d="M24.7578 20.7648V22.7457H421.022V257.17H379.418L366.391 270.831H64.0432V288.044H45.0144V274.383H24.0758V77.7316L14.3227 70.218V83.196L7.50228 76.3654V37.6363H24.485V12.8414H7.50228V0L-0.272949 9.76768V109.972L2.65982 113.25V163.045L8.72995 168.578V304.027L19.7108 314L21.0748 312.771L10.6396 303.276V167.826L4.56951 162.294V115.436L7.50228 118.783V91.8708L14.3227 98.7013V298.153L24.0758 307.511V299.109H45.0144V289.683H66.0212V272.402H366.936L379.963 259.356H422.659V20.7648H24.7578ZM20.8702 16.7348V33.6746H7.7751V16.7348H20.8702ZM7.7751 86.4063V81.9665L14.5955 88.797V93.2369L7.7751 86.4063Z" fill="#FE7A2B"></path></g><defs><filter id="filter0_d_424:583" x="-4.27295" y="0" width="430.932" height="322" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset dy="4"></feOffset><feGaussianBlur stdDeviation="2"></feGaussianBlur><feComposite in2="hardAlpha" operator="out"></feComposite><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_424:583"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_424:583" result="shape"></feBlend></filter></defs></svg>
</div>
<div >
          <div className="info-content"><div className="text-white">
            <p><b>Total Sales: </b> 19.5K ETH</p><p><b>Total Volume: </b>
             7.0M</p><p>Most competitive gaming metaverse where the battles are fierce.</p></div>

          </div>
         
        </div>
      
  </>: null
}
</div>
<div className="lower__part">
{
  this.state.card3 ? <>
    <div className="">
      <div className="info__card__title">
    <img src={title} alt=""/>
  </div>
  <div className="info__card__title__text">
    <h2>Splinterlands</h2>
  </div></div>
  <div className="bkg__info__card" >
<svg className="bkg__img" viewBox="0 0 423 314" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_424:583)"><path d="M24.7578 20.7648V22.7457H421.022V257.17H379.418L366.391 270.831H64.0432V288.044H45.0144V274.383H24.0758V77.7316L14.3227 70.218V83.196L7.50228 76.3654V37.6363H24.485V12.8414H7.50228V0L-0.272949 9.76768V109.972L2.65982 113.25V163.045L8.72995 168.578V304.027L19.7108 314L21.0748 312.771L10.6396 303.276V167.826L4.56951 162.294V115.436L7.50228 118.783V91.8708L14.3227 98.7013V298.153L24.0758 307.511V299.109H45.0144V289.683H66.0212V272.402H366.936L379.963 259.356H422.659V20.7648H24.7578ZM20.8702 16.7348V33.6746H7.7751V16.7348H20.8702ZM7.7751 86.4063V81.9665L14.5955 88.797V93.2369L7.7751 86.4063Z" fill="#FE7A2B"></path></g><defs><filter id="filter0_d_424:583" x="-4.27295" y="0" width="430.932" height="322" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset dy="4"></feOffset><feGaussianBlur stdDeviation="2"></feGaussianBlur><feComposite in2="hardAlpha" operator="out"></feComposite><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_424:583"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_424:583" result="shape"></feBlend></filter></defs></svg>
</div>
<div className="info__card">
          <div className="info-content"><div className="text-white">
            <p><b>Total Sales: </b> 15.8 ETH</p><p><b>Total Volume: </b>
             2.3K</p><p>Most competitive gaming metaverse where the battles are fierce.</p></div>

          </div>
        </div>


  </>: null
}
{
  this.state.card4 ? <>
    <div className="">
      <div className="info__card__title">
    <img src={title} alt=""/>
  </div>
  <div className="info__card__title__text">
    <h2>Axie Infinity</h2>
  </div>
  </div>
  <div className="bkg__info__card" >
<svg className="bkg__img" viewBox="0 0 423 314" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_424:583)"><path d="M24.7578 20.7648V22.7457H421.022V257.17H379.418L366.391 270.831H64.0432V288.044H45.0144V274.383H24.0758V77.7316L14.3227 70.218V83.196L7.50228 76.3654V37.6363H24.485V12.8414H7.50228V0L-0.272949 9.76768V109.972L2.65982 113.25V163.045L8.72995 168.578V304.027L19.7108 314L21.0748 312.771L10.6396 303.276V167.826L4.56951 162.294V115.436L7.50228 118.783V91.8708L14.3227 98.7013V298.153L24.0758 307.511V299.109H45.0144V289.683H66.0212V272.402H366.936L379.963 259.356H422.659V20.7648H24.7578ZM20.8702 16.7348V33.6746H7.7751V16.7348H20.8702ZM7.7751 86.4063V81.9665L14.5955 88.797V93.2369L7.7751 86.4063Z" fill="#FE7A2B"></path></g><defs><filter id="filter0_d_424:583" x="-4.27295" y="0" width="430.932" height="322" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset dy="4"></feOffset><feGaussianBlur stdDeviation="2"></feGaussianBlur><feComposite in2="hardAlpha" operator="out"></feComposite><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_424:583"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_424:583" result="shape"></feBlend></filter></defs></svg>
</div>
<div className="info__card">
          <div className="info-content"><div className="text-white">
            <p><b>Total Sales: </b> 27.5K ETH</p><p><b>Total Volume: </b>
            282.8K</p><p>Most competitive gaming metaverse where the battles are fierce.</p></div>

          </div>
        </div>

       
  </>: null
}
{
  this.state.card5 ? <>
    <div className="">
      <div className="info__card__title">
    <img src={title} alt=""/>
  </div>
  <div className="info__card__title__text">
    <h2>Decentraland</h2>
  </div></div>
  <div className="bkg__info__card" >
<svg className="bkg__img" viewBox="0 0 423 314" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_d_424:583)"><path d="M24.7578 20.7648V22.7457H421.022V257.17H379.418L366.391 270.831H64.0432V288.044H45.0144V274.383H24.0758V77.7316L14.3227 70.218V83.196L7.50228 76.3654V37.6363H24.485V12.8414H7.50228V0L-0.272949 9.76768V109.972L2.65982 113.25V163.045L8.72995 168.578V304.027L19.7108 314L21.0748 312.771L10.6396 303.276V167.826L4.56951 162.294V115.436L7.50228 118.783V91.8708L14.3227 98.7013V298.153L24.0758 307.511V299.109H45.0144V289.683H66.0212V272.402H366.936L379.963 259.356H422.659V20.7648H24.7578ZM20.8702 16.7348V33.6746H7.7751V16.7348H20.8702ZM7.7751 86.4063V81.9665L14.5955 88.797V93.2369L7.7751 86.4063Z" fill="#FE7A2B"></path></g><defs><filter id="filter0_d_424:583" x="-4.27295" y="0" width="430.932" height="322" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset dy="4"></feOffset><feGaussianBlur stdDeviation="2"></feGaussianBlur><feComposite in2="hardAlpha" operator="out"></feComposite><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_424:583"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_424:583" result="shape"></feBlend></filter></defs></svg>
</div>
<div className="info__card">
          <div className="info-content"><div className="text-white">
            <p><b>Total Sales: </b> 52.8K ETH</p><p><b>Total Volume: </b>
             97.1K</p><p>Most competitive gaming metaverse where the battles are fierce.</p></div>

          </div>
        </div>

      
  </>: null
}










              </div>
       
           


            
        {/* <MediaQuery maxWidth={800}>
        
        <div className="carousel__body"
        style={{      
        
         width:'30%',
         height: "22rem", 
         position:'relative' ,
         left:0,right:0,
         marginLeft:'auto',
         marginRight:'auto',
         }}>
           <Carousel
             slides={this.slides}
             goToSlide={this.state.goToSlide}
             offsetRadius={this.state.offsetRadius}
             showNavigation={false}
             goToSlideDelay={200}
             animationConfig={this.state.config}
               
           />
           
         </div>
        </MediaQuery> */}

</div>
      </Grid> 

     

</Grid>
</Box>


{/* 
<NavLink exact to="/metaverse/:planet"> 
            <div >
                <div className="metaverse__btn__img">
                      <img src={btn} alt=""/>
                </div>
              
          
                <div className="metaverse__btn__img__text">
                  KNOW MORE
                </div>
       
         
            </div> 
            </NavLink> */}
      </div>
    </>);
  }
}
