import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Appbar.css"
import list from "./list (3).png"
import close from "./close (2).png"
import logo from "./logo.png"

function Appbar() {

  const [click, setClick] = React.useState(false);
  const [wallet, setWallet] = React.useState(true);
  const handleClick = () => setClick(!click);
 
  const Close = () => setClick(false);
    return (<>
    <div className="navbar">
     {/* <div className={click ? "main-container" : ""} onClick={()=>Close()} />
      <nav className="navbar" onClick={(e)=>e.stopPropagation()}>
        <div className="nav-container">
        <div>
          <a href="home" className="nav-logo glow">
           
            <img className="glow" width="60%" height="60%" src="https://demo.acknoledger.com/assets/logo.72d2ab8d.png" alt="" />
           
            <i className="fa fa-code"></i>
          </a>
          </div>
          <div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
          
            <li className="nav-item">
            <a href="/explore"
              activeClassName="active"
              className="nav-links pay"
           
            >
              GO BACK
            </a>
          </li>

        </ul>
        </div>
        <div >
        <a href="/explore"
              activeClassName="active"
              className="nav-links pay"
           
            >
              GO BACK
            </a> 
          </div> 
        </div>
      </nav> */}
      <div>
      <img className="glow" width="60%" height="60%" src="https://demo.acknoledger.com/assets/logo.72d2ab8d.png" alt="" />
      </div>
      <div>
        GO BACK
      </div>
    
    </ div>
   </> )
}

export default Appbar
