import React from 'react'
import DCarousel from '../Carousel/DCarousel'
import "./Section2.css"
function Section2() {
    return (<>
        <div >
            <DCarousel/>
        </div>
   </> )
}

export default Section2
