import React from 'react'
import "./Individual.css"
import card from "./Union.png"
import planet from "./pngwing.png"
import lineH from "./VectorLine.png"
import mobile from "./Vector.png"
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import axieblue from "./axieblue.png"
import axiegreen from "./axiegreen.png"
import axiered from "./axiered.png"
import axiegrey from "./axiegrey.png"
import top from "../Carousel/Group 24.png"
import btn from "../Subscribe/Rectangle 414.png"
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
function Individual() {
    const data=useLocation()
    return (<>
        <div style={{padding:'1rem',marginTop:'2rem'}}>
            <Box sx={{flexGrow:1}}>
                <Grid container xs={12} md={12}>
                    <Grid className="grid__bg" item xs={12} md={6}>
                    {/* <img className="card__left" src={card} alt=""/> */}
                    <div className="content">
                            <h1>{data.state.name}</h1>
                            <p><span style={{fontWeight:'bolder'}}>Total Sale:</span> {data.state.sales}</p>
                            <p><span style={{fontWeight:'bolder'}}>Total Volume:</span> {data.state.volume}</p>
                            <p><span style={{fontWeight:'bolder'}}>Axies Sold:</span> 50,000</p>
                            <img className="lineH" src={lineH} alt=""/>
                            <div style={{marginTop:'-1rem'}}>
                            <h2>Popular Axies</h2>
                            <Box sx={{flexGrow:1}}>
                                <Grid container xs={12} md={12} spacing={1}>
                                    <Grid style={{display:'flex' , flexDirection:'column'}} item xs={6} md={3}>
                                        <div style={{backgroundColor:'white',borderRadius:20}}>
                                        <div className="axie__header">
                                        Axie #403879
                                        </div>
                                        <div>
                                        <img src={axieblue} alt=""/>
                                        </div>
                                        </div>
                                    </Grid>
                                    <Grid style={{display:'flex' , flexDirection:'column'}} item xs={6} md={3}>
                                        <div style={{backgroundColor:'white',borderRadius:20}}>
                                        <div className="axie__header">
                                        Axie #403879
                                        </div>
                                        <div>
                                        <img src={axiegreen} alt=""/>
                                        </div>
                                        </div>
                                    </Grid>
                                    <Grid style={{display:'flex' , flexDirection:'column'}} item xs={6} md={3}>
                                        <div style={{backgroundColor:'white',borderRadius:20}}>
                                        <div className="axie__header">
                                        Axie #403879
                                        </div>
                                        <div>
                                        <img src={axiered} alt=""/>
                                        </div>
                                        </div>
                                    </Grid>
                                    <Grid style={{display:'flex' , flexDirection:'column'}} item xs={6} md={3}>
                                        <div style={{backgroundColor:'white',borderRadius:20}}>
                                        <div className="axie__header">
                                        Axie #403879
                                        </div>
                                        <div>
                                        <img src={axiegrey} alt=""/>
                                        </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                            </div>
                     </div>
                    </Grid>
                    <Grid className="planet__grid" item xs={12} md={6}>
                        <div className="planet__responsive">
                        <img className="top__of__planet" src={top} alt=""/>
                        <div className="top__of__planet__text">{data.state.name}</div>
                        </div>
                    <img className="planet__right" src={data.state.image} alt=""/>
                    </Grid>

                 
                    <Grid style={{textAlign:'center'}} item xs={12} md={12}>
           
                  <div className="know__more">
                            <div className="metaverse__btn__img__individual">
                                <img src={btn} alt=""/>
                            </div>
                        
                    
                            <div className="metaverse__btn__img__text__individual">
                            KNOW MORE
                            </div>
                        </div>
       
                    </Grid>
                </Grid>
            </Box>

    
    

        </div>


   </> )
}

export default Individual
