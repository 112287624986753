import React from 'react'
import "./Subscribe.css"
import btn from "./Rectangle 414.png"
import { Grid, Box } from '@mui/material'
import tele from "./telegram (2).png"
import leftPlanet from "./cut-planet 1.png"
function Subscribe() {
    return (<>
        <div>
            <div className="planet_1">
                <img src="https://demo.acknoledger.com/assets/subscribe_planet_1.351b03c9.png" alt=""/>
            </div>
            <div className="planet_2">
                <img src={leftPlanet} alt=""/>
            </div>
            <div style={{textAlign:'center'}}>
           <div> 
           <h1 className="subscribe__text">AcknoLedger will be launching its own tokens<br></br>
                    to unlock benefits for its users.</h1>
            </div>
            <p className="susbscribe__2nd__text">Subscribe to be the first to hear about them.</p>
            </div>
            <div>
                <input name="email"  className="subscribe__input" placeholder="E-mail" value=""/>
        
                </div>
                <div >
                <input name="text"  className="subscribe__input" placeholder="Telegram-id" value=""/>
                </div>
                <div style={{marginTop:'2rem'}}>
                <div className="subscribe__btn__img">
                      <img src={btn} alt=""/>
                </div>
                <div className="subscribe__btn__img__text">
                  SUBSCRIBE
                </div>
                </div>
           <Box sx={{flexGrow: 1}}>
               <Grid xs={12} md={12} justifyContent="space-around" style={{textAlign:'center'}} container alignItems="center">
                   <Grid  className="social1" item xs={12} md={6}>
                       <img src={tele} alt=""/> <span><a href="#">@acknoledger</a></span>
                    </Grid>
                    <Grid   className="social2" item xs={12} md={6}>
                    <img src={tele} alt=""/> <span><a href="#">@acknoledgercommunity</a></span>
                    </Grid>
                    <Grid  style={{textAlign:'center',color:'white',position:'relative',zIndex:100000}} item xs={12} md={12}>
                        PRIVACY POLICY
                    </Grid>
               </Grid>
           </Box>
        </div>
   </> )
}

export default Subscribe
