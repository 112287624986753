import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Team.css"
function Team() {
    return (
        <div className="teams">
            <div className="team__header">MEET OUR TEAM</div>
            <div className="sky">

        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
        <div className="star"></div>
    </div>
            <br></br>

            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>

                    <Grid className="person__container" container spacing={2}>
                    <Grid className="img__align" item xs={12} md={12}>
                      <div className="ellipse " ></div>
                        <img className="team__img  zoom-in-out-box" style={{borderRadius:'50%'}} width="70%" height="80%" src="https://acknoledger.com/wp-content/uploads/2019/10/Yash-Dahenkar.png" alt=""/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="name align"> YASH DAHENKAR </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="position align"> CO-FOUNDER </div>
                    </Grid>
                  </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                     
                    <Grid container spacing={2}>
                    <Grid className="img__align" item xs={12} md={12}>
                    <div className="ellipse" ></div>
                    <img style={{borderRadius:'50%'}} width="70%" height="80%" src="https://acknoledger.com/wp-content/uploads/2019/10/Abhishek-Rajpurohit.jpg" alt=""/>
                       </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="name align"> ABHISHEK RAJPUROHIT </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="position align"> CO-FOUNDER </div>
                    </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    
                   
                    <Grid container spacing={2}>
                    <Grid className="img__align" item xs={12} md={12}>
                    <div className="ellipse" ></div>
                    <img style={{borderRadius:'50%'}} width="70%" height="80%" src="	https://acknoledger.com/wp-content/uploads/2019/10/Kuntal_1.jpg" alt=""/>
                     </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="name align"> KUNTAL GANGULY </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="position align"> CO-FOUNDER </div>
                    </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    
                    
                    <Grid container spacing={2}>
                    <Grid className="img__align" item xs={12} md={12}>
                    <div className="ellipse" ></div>
                    <img style={{borderRadius:'50%'}} width="70%" height="80%" src="	https://acknoledger.com/wp-content/uploads/2019/10/Riya-Rana.jpg" alt=""/>
                      </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="name align"> RIYA RANA</div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="position align"> BLOCKCHAIN DEVELOPER </div>
                    </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                   
                    
                    <Grid container spacing={2}>
                    <Grid className="img__align" item xs={12} md={12}>
                    <div className="ellipse" ></div>
                    <img style={{borderRadius:'50%'}} width="70%" height="80%" src="https://acknoledger.com/wp-content/uploads/2019/10/Akshat-Sanwal.jpg" alt=""/>
                         </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="name align"> AKSHAT SANWAL </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="position align"> SOCIAL MEDIA LEAD </div>
                    </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                  
                    
                    <Grid container spacing={2}>
                    <Grid className="img__align" item xs={12} md={12}>
                    <div className="ellipse" ></div>
                    <img style={{borderRadius:'50%'}} width="70%" height="80%" src="https://acknoledger.com/wp-content/uploads/2019/10/Chaitra-Subhakaran.png" alt=""/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="name align"> CHAITRA SUBHAKARAN </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="position align"> UX-STRATEGIST</div>
                    </Grid>
                        </Grid>
                    </Grid>
         
                </Grid>
            </Box>
            {/* <div class="shapes">
        <div class="rocket-holder">
            <img src="https://i.postimg.cc/6qGrrNbV/rocket.png" class="rocket"/>
        </div>
        
        <div class="shape1 shape0"></div>
        <div class="shape2 shape0"></div>
        <div class="shape3 shape0"></div>
        <div class="shape4 shape0"></div>
        <div class="shape5 shape0"></div>
        <img src="https://demo.acknoledger.com/storage/axie-infinity-6.gif" class="planet1 planets"/>
        <img src="https://demo.acknoledger.com/storage/axie-infinity-7.gifg" class="planet2 planets"/>
        <img src="https://demo.acknoledger.com/storage/axie-infinity-9.gif" class="planet4 planets"/>
        <img src="https://demo.acknoledger.com/storage/axie-infinity.gif" class="planet5 planets"/>
        <img src="https://demo.acknoledger.com/storage/axie-infinity-5.gif" class="planet6 planets"/>
        <img src="https://demo.acknoledger.com/storage/axie-infinity-6.gif" class="planet7 planets"/>
        <img src="https://demo.acknoledger.com/storage/axie-infinity-7.gif" class="planet8 planets"/>
        <img src="https://demo.acknoledger.com/storage/axie-infinity-9.gif" class="planet9 planets"/>

    </div> */}

        </div>
    )
}

export default Team
