import './App.css';
import Section1 from './components/Section1/Section1';
import {BrowserRouter,Switch,Route} from "react-router-dom"
import Section2 from './components/Section2/Section2';
import Appbar from './components/Appbar/Appbar';
import Individual from './components/IndividualCard/Individual';
import Subscribe from './components/Subscribe/Subscribe';
import Explore from './components/Explore/Explore';
import Team from './components/Team/Team';
import Carousel3D from './components/Carousel3D/Carousel3D';
function App() {
  return (
    <div className="App">
      <div className="app">
        <div>
      <BrowserRouter>
      <Appbar/>
      <Switch>
        <Route exact path="/" ><Section1/></Route>
        <Route exact path="/explore" >
          <Explore/>

          <div style={{marginTop:'3rem',textAlign:'center'}}>
          <Section2/>
         <div > <Team/> </div>
         <div style={{position:'relative'}}>  <Subscribe/></div>
    
         </div>

          </Route>
     
        <Route exact path="/metaverse/:planet" ><Individual/></Route>
 
      </Switch>
      </BrowserRouter></div>
      </div>
      
      <>
    
      </>
    
    </div>
  );
}

export default App;
