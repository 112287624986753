import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import "./Explore.css"
import line from "./Rect.png"
import btn from "../Subscribe/Rectangle 414.png"
import { NavLink } from 'react-router-dom'
function Explore() {
    return (<>
        <div className="explore__bkg">
            <Box sx={{flexGrow: 1,marginTop:'2rem'}}>
                <Grid container xs={12} md={12}>
                    <Grid  item xs={2} md={2}>
                        <img src={line} alt=""/>
                    </Grid>
    
                    <Grid style={{textAlign:'left'}} item xs={9} md={5}>
                        <div className="explore__title">WELCOME TO <br></br> <span className="explore__ack">ACKNOLEDGER</span></div>
                        <p className="explore__text">Explore different metaverses and <br></br>
                            Search for your favourite crypto goods</p>
                            <p className="explore__text2">on World’s first comprehensive metaverse aggregator site</p>
         
             <div style={{marginTop:'2rem'}}>
                <div className="explore__btn__img">
                      <img src={btn} alt=""/>
                </div>
                <div className="explore__btn__img__text">
                  Explore 
                </div>
                </div>
    
                    </Grid>
                </Grid>
            </Box>
        </div>
   </> )
}

export default Explore
